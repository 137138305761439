// Fonts
@charset "UTF-8";
@import './utilities/colors';
@import './customer/colors';

// Vendor import
@import '~bootstrap/scss/bootstrap';
@import '~datatables.net-bs4/css/dataTables.bootstrap4';
@import '~lightslider/src/css/lightslider';
@import '~ekko-lightbox/dist/ekko-lightbox';
@import '~datatables.net-responsive-bs4/css/responsive.bootstrap4';
@import '~dropzone/dist/dropzone.css';
@import '~jquery-bar-rating/dist/themes/bootstrap-stars.css';
@import '~select2/src/scss/core';

// utilities import
@import './utilities/variables';
@import './utilities/mixins';
@import './utilities/inputs';

@import './vendor/eprivacy/eprivacy';
@import './vendor/feedbackcontact/feedbackcontact';

// Base styling
@import './base/fonts';
@import './base/defaults';

// layout styling
@import './layout/header';
@import './layout/nav';
@import './layout/megamenu';
@import './layout/footer';
@import './layout/filter';
@import './layout/pages';

@import './layout/pace';
@import './layout/slider';
@import './layout/category';
@import './layout/products';
@import './layout/result';
@import './layout/revenue';
@import './layout/searchbar';
@import './layout/dropzone';
@import './layout/contact';
@import './layout/cms';
@import './layout/table';
@import './layout/sortable';
@import './layout/sidebar';
@import './layout/downloads';
@import './layout/sidemenu';
@import './layout/icons';
@import './layout/lang';
@import './layout/fallback';
@import './layout/modal';
@import './layout/youtube';
@import './layout/details';
@import './layout/availability';
@import './layout/bookmarks';
@import './layout/datatables';
@import './layout/popover';
@import './layout/conditions';
@import './layout/dashboard';
@import './layout/documents';
@import './layout/price_history';
@import './layout/sample_form';
@import './layout/productReturn';

// components styling

// customer
// @import './customer/variables_customers';
// @import './customer/customers';
@import './base/print';

.tab-content {
    width: 100% !important;
}

.br-theme-bootstrap-stars .br-widget a:after {
    content: '\f005';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: #d2d2d2;
}
.br-theme-bootstrap-stars .br-widget a.br-active:after {
    color: $primary;
}
.br-theme-bootstrap-stars .br-widget a.br-selected:after {
    color: $primary;
}

table tr {
    &.odd {
        background-color: transparent;
    }

    &.even {
        background-color: $gray-25;
    }
}

.products tr,
.details tr {
    transition: 0.5s;

    &:hover {
        background-color: $gray-50;
    }

    &.active {
        background-color: $primary-75 !important;
    }
}

.datatable {
    td {
        @media (max-width: 768px) {
            a {
                display: inline;
                i {
                    font-size: 26px;
                    margin: 12px;
                }
            }
        }
    }
}

.epp-cookie {
    @media (max-width: 500px) {
        display: none !important;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
}
input[type='number'] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.maincontent {
    --spacer: 15px;

    padding: calc(var(--spacer) * 1.5) var(--spacer);
    background: white;
}

.main,
main {
    --min-height: calc(100vh - 74.47px - 240px);
    min-height: var(--min-height);

    @include media-breakpoint-up(lg) {
        --min-height: calc(100vh - 166.67px - 232.5px);
    }
}

.product-content {
    /* height: 100%; */
    height: auto;
    padding: 15px;
    background-color: white;
}

.reset-input {
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        opacity: 1 !important;
        appearance: auto !important;
    }

    -moz-appearance: initial;
}

.select2-fullwidth {
    .select2 {
        width: 100% !important;
    }
}

p.error {
    color: red;
}

p.success {
    color: green;
}

@media screen and (max-width: 768px) {
    .container-md,
    .container-sm,
    .container {
        max-width: 100% !important;
    }
}

.pw-requirements {
    margin-bottom: 1rem;

    li {
        list-style: inside !important;
    }
}

.valid {
    color: green;
}

.invalid {
    color: red;
}
