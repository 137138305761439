.header {
    --padding: 0;
    --margin: 15px;

    padding-top: var(--padding);

    &.header--sticky {
        position: sticky;
        top: 0;

        background: white;
        z-index: 1002;

        margin-bottom: var(--margin);
    }

    @include media-breakpoint-up(lg) {
        --padding: 0;
        --margin: 30px;
    }
}
