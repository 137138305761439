.footer {
    --padding: 20px;
    --font-size: 15px;

    display: flex;
    align-items: center;
    background-color: white;
    padding-top: var(--padding);
    padding-bottom: var(--padding);

    border-top: 1px solid $border-color;

    color: $font-lighter;
    font-size: var(--font-size);
    font-weight: 100;

    .footer__content {
        flex-direction: column;
        justify-content: space-between;

        .footer__left,
        .footer__center,
        .footer__right {
            justify-content: center;
        }
    }

    a {
        display: inline-flex;
        width: auto;
        padding: 0 10px 0 0;

        color: $font-lighter;
        text-transform: none;
        text-decoration: none;

        transition: all 200ms ease-in-out;
        font-size: var(--font-size);

        &:hover,
        &:focus,
        &:active {
            color: $font-lighter--hover;
        }

        &:last-of-type {
            padding: 0;
        }
    }

    .footer__col {
        --width: 100%;
        --margin: 10px;

        display: flex;
        align-items: center;
        width: var(--width);
        margin-bottom: var(--margin);

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-up(lg) {
        --padding: 20px;

        .footer__content {
            flex-direction: row;

            .footer__right {
                margin-top: 0;
            }
        }

        a {
            padding: 0 20px 0 0;
        }

        .footer__left {
            justify-content: start;
        }

        .footer__center {
            justify-content: center;
        }

        .footer__right {
            justify-content: end;
        }

        .footer__col {
            --width: 33.33%;
            --margin: 0;
        }
    }
}

.footer-before {
    --height: 120px;

    width: 100%;
    height: var(--height);
    background-color: $fallback;

    background-image: url(/assets/fapa-title.png);
    background-position: bottom right;
    background-size: cover;

    @extend .mt-4;

    @include media-breakpoint-up(lg) {
        --height: 145px;
    }
}
