.downloads {
    /* --height: 100px; */
    --height: 65px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    position: relative;
    background: $background-light;
    padding: 0;
    border-radius: 0;

    height: auto;
    width: 100%;

    margin-bottom: $spacing;

    transition: all 300ms ease-in-out;

    .fapa__button--icon {
        span {
            margin-right: 0;
            transform: translate(0, 3px);
        }

        i {
            background-color: transparent;
            color: $primary;
            font-size: 18px;
        }
    }

    &__title {
        flex: 1;

        font-size: 18px;
        font-weight: 500;
        color: $font;
        transform: translate(0, 3px);

        padding: 15px 0 0 15px;
    }

    &__type {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        height: var(--height);
        background-color: $background-dark;
        padding: 0 15px;

        width: 100%;

        i {
            font-size: 30px;
        }
    }

    &__button {
        padding: 5px 0 10px 15px;
    }

    &:last-of-type {
        //margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        --height: 100px;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        padding: 10px $spacing 10px 0;
        height: var(--height);

        &__title {
            margin-left: $spacing;
            padding: 0;
        }

        &__type {
            justify-content: center;
            width: var(--height);
        }

        &__button {
            padding: 0;
        }
    }
}

.product-slider {
    --slider-height: 340px;
    position: relative;
    margin-bottom: $spacing * 2;

    min-height: var(--slider-height);

    // .lSSlideOuter .lSPager.lSGallery img {
    //     height: 100%;
    //     width: 100%;
    //     object-fit: cover;
    // }

    .lightSlider {
        border: 1px solid $background-dark;
        list-style: none;
        padding: 0;
    }

    .lightSlider.lsGrab > * {
        // height: 30px;
        a {
            img {
                border-radius: $borderRadius;
                min-width: 100%;
            }
        }
    }

    .lSPager {
        display: flex;
        max-height: 75px;
        width: 100% !important;
        li {
            height: 75px !important;
            width: 75px !important;

            border-radius: 0;

            &.active {
                border-radius: 0 !important;
                border: 2px solid $gray;
            }

            &:hover {
                border-radius: 0 !important;
            }
        }
    }

    &__loader {
        --height: var(--slider-height) !important;
        --margin: 0px !important;
        --width: calc(100%) !important;
        --border-radius: 5px !important;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}

.detail-info__refresh {
    display: flex;
    margin-bottom: $spacing - 5px;

    a {
        background: $gray-25;
    }

    span {
        color: white;
    }

    .detail-info__content {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: $spacing;
        border-radius: 5px;

        background-color: $primary;
    }
}

.product-informations {
    display: flex;
    flex-direction: column;
    align-items: end;

    &__footer {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: end;

        button {
            width: fit-content;
        }
    }
}
