/**
* Details.blade.php
*/
.breadcrump {
    a,
    span {
        font-size: 14px;
        font-weight: 200;
    }

    span {
        &:last-of-type {
            display: none;
        }
    }
}

.product-slider .lSSlideOuter .lSGallery.lSPager {
    margin-top: 15px !important;

    li.active {
        border-radius: 0 !important;
        border-color: $primary;
    }
}

.product-slider .lightSlider.lsGrab.lSSlide {
    border: 0 !important;
}

/**
* Bookmarks
*/
.page-bookmarks,
.page-favorites {
    .bookmark-selection {
        margin-top: -5px;
        margin-bottom: 30px;

        &__name {
            font-size: 20px;
            font-weight: 400;
            color: $primary;
        }

        &__control {
            position: relative;
            font-weight: 200;
            color: $font;
            cursor: pointer;

            display: block;
            width: 100%;
            margin-left: 0;

            i {
                margin-right: 5px;
                opacity: 0.5;
                transition: opacity 0.2s ease-in-out;
            }

            &:hover {
                i {
                    opacity: 1;
                }
            }
        }

        &__options {
            opacity: 0;
            appearance: 0;

            pointer-events: none;

            position: absolute;
            top: 0;

            left: 50%;
            transform: translate(-50%, 25px);

            min-width: calc(100vw - 20px);
            max-width: calc(100vw - 20px);

            background-color: white;
            padding: 20px 15px;
            z-index: 1001;

            list-style: none;

            box-shadow: 2.8px 2.8px 2.2px rgba(0, 0, 0, 0.02), 6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028), 12.5px 12.5px 10px rgba(0, 0, 0, 0.035), 22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
                41.8px 41.8px 33.4px rgba(0, 0, 0, 0.05), 100px 100px 80px rgba(0, 0, 0, 0.07);

            li {
                margin-bottom: 10px;
                transition: all 0.2s ease-in-out;

                &.active {
                    color: $primary;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:hover {
                    color: $primary;
                }
            }

            &.show {
                opacity: 1;
                appearance: visible;
                pointer-events: all;
            }
        }

        @include media-breakpoint-up(md) {
            &__control {
                margin-left: 25px;
                display: inline;
            }

            &__options {
                max-width: 200px;
                min-width: 170px;
            }
        }
    }

    .bookmark-content {
        background-color: white;
        padding: 15px;
        border: 1px solid $background;

        &__noresult {
            display: block;
            transform: translate(0, 3px);
        }
    }

    .bookmark-controls {
        height: auto;
        width: 100%;
        background-color: white;

        margin-bottom: 15px;
        padding: 20px;

        border: 1px solid $background;

        &__col {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &--nowrap {
                flex-direction: row;
                flex-wrap: nowrap;
            }
        }

        &__row {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: auto;

            .form-group {
                display: flex;
                flex-direction: column;

                /* min-width: 250px; */
                width: 100%;
                /* margin-right: 20px; */
                margin-bottom: 0;

                &:first-of-type {
                    margin-bottom: 20px;
                }

                select {
                    height: 40px;
                }
            }
        }

        &__footer {
            margin-top: 20px;
            justify-content: center;

            button {
                &:first-of-type {
                    &.fapa__button--secondary {
                        padding-right: 0;
                    }
                }
            }
        }

        .fapa__button--icon {
            margin-left: 15px;
            opacity: 0.3;

            i {
                background-color: black;
            }
        }

        @include media-breakpoint-up(lg) {
            &__col {
                flex-wrap: nowrap;
            }

            &__row {
                flex-direction: row;

                .form-group {
                    min-width: 250px;
                    max-width: 250px;
                    margin-right: 20px;

                    &:first-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            &__footer {
                margin-top: 0;
                justify-content: flex-end;
            }
        }

        @include media-breakpoint-down(md) {
            .bookmark-controls__footer {
                flex-direction: column;

                .fapa__button {
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                    margin-left: 0 !important;

                    span {
                        text-align: center;
                        width: 100%;
                    }

                    &:first-of-type {
                        padding: 0;
                        height: 20px;
                    }

                    &--primary {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    // Deactivate option checkbox
    .select2-results__option {
        &::before {
            content: none;
        }
    }

    // Deactivate dropdown searchbox
    .select2-search--dropdown {
        display: none;
    }
}

.icon-long-storage-time {
    height: auto;
    max-width: 24px;
}
.modal .modal-text-left {
    text-align: left !important;
}