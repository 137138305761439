.searchbar {
    //position: sticky;
    position: relative;
    // top: 212px;
    width: 100%;
    height: 80px;

    margin-top: -30px;
    z-index: 100;

    .searchbar__container {
        display: flex;
        align-items: center;
        position: relative;
        background: #f7f7f7;
        width: calc(100vw - #{$spacing});
        height: 80px;
        top: 0;

        @include centerElement();
    }

    .searchbar__content {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        h1,
        h2 {
            display: none;
            align-items: center;
            margin-bottom: 5px;

            font-size: 18px;
        }
        form {
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            width: 100%;

            input {
                border: 0;
                outline: 0;
            }

            button {
                margin-left: $spacing;
            }
        }
    }

    @include media-breakpoint-up(md) {
        height: 100px;

        .searchbar__container {
            height: 100px;
        }

        .searchbar__content {
            flex-wrap: nowrap;
            h1,
            h2 {
                display: inline-flex;
                margin-bottom: 0;
                font-size: 25px;
            }
            form {
                width: 60%;
            }
        }
    }
}

.searchbar-fullwidth {
    --spacing: 6px;

    position: relative;
    width: calc(100vw - 15px);

    left: 50%;
    transform: translate(-50%, -30px);

    padding: calc(var(--spacing) * 6) 0 calc(var(--spacing) * 5) 0;
    background: url(/assets/background.jpg);

    .searchbar__content {
        h2,
        h4 {
            color: white;
            font-weight: 100;
            margin-bottom: 0;
        }

        h2 {
            font-size: 48px;
        }

        form {
            display: flex;
            flex-direction: row;
            padding: calc(var(--spacing) / 2);
            border-radius: 2px;
            background-color: white;

            input {
                margin-right: -1px;
                border-radius: 0;
                border-color: $gray;
                padding-top: 11px;
                font-weight: 300;
            }
        }
    }
}

.search-sidebar {
    --spacing: 20px;
    padding: var(--spacing);
    background-color: white;
    height: fit-content;

    position: relative;

    ul {
        padding: 0;
        padding-left: var(--spacing);
        list-style: none;

        li {
            position: relative;

            height: 40px;
            display: flex;
            align-items: center;
            padding-left: 10px;
            padding-top: 6px;
            border-radius: 3px;

            margin-bottom: 5px;

            &:first-of-type {
                background-color: rgba($color: $gray, $alpha: 0.5);
            }
        }

        .search-sidebar__ul > li {
            padding-left: var(--spacing);
            background-color: rgba($color: $gray, $alpha: 0.3);
        }

        .search-sidebar__ul--deep > li {
            background-color: rgba($color: $gray, $alpha: 0.1);
        }

        .search-sidebar__accordion {
            display: flex;
            justify-content: flex-end;

            position: absolute;
            height: 20px;
            width: 100px;
            right: 10px;
            top: 50%;

            transform: translate(0, -50%);

            i {
                display: block;
                --height: 20px;
            }
        }
    }

    .category_tree {
        cursor: pointer;
        height: 40px;
        padding: 0;
        overflow: hidden;

        &.show {
            height: 100%;

            .search-sidebar__accordion i {
                transform: rotate(180deg);
            }
        }

        @include media-breakpoint-up(lg) {
            overflow: visible;
            height: 100%;

            .search-sidebar__accordion i {
                transform: rotate(180deg);
            }
        }
    }

    @include media-breakpoint-down(md) {
        background-color: transparent;
    }
}

.typeahead {
    &.dropdown-menu {
        z-index: 3;
    }
}

.autocomplete-suggestions {
    background-color: #fff;
    border: 1px solid #b1b8bc;
    border-radius: 0;
    padding: 0.5rem 0;
    transform: translate(-1px, 10px);
    width: calc(100% + 2px);
    overflow: auto;
}
.autocomplete-suggestion {
    background-color: transparent;
    border: 0;
    clear: both;
    color: #212529;
    display: block;
    font-weight: 400;
    padding: 0.25rem 1.5rem;
    text-align: inherit;
    white-space: nowrap;
    width: 100%;

    &:hover {
        background-color: #e9ecef;
        color: #16181b;
        text-decoration: none;
    }
}
// .autocomplete-selected {
//     background: #f0f0f0;
// }
// .autocomplete-suggestions strong {
//     font-weight: normal;
//     color: #3399ff;
// }
// .autocomplete-group {
//     padding: 2px 5px;
// }
// .autocomplete-group strong {
//     display: block;
//     border-bottom: 1px solid #000;
// }
