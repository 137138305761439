.fallback {
    --height: 50px;
    --margin: 15px;
    --width: 200px;
    --border-radius: 4px;

    height: var(--height);
    width: var(--width);
    margin-bottom: var(--margin);
    border-radius: var(--border-radius);

    @include sceletonAnimation();

    &--headline {
        --height: 45px;
    }

    &--result {
        --height: 200px;
        --width: 100%;
        --margin: 10px;
    }

    &--span {
        --height: 20px;
        --margin: 10px;
        --width: 150px;
    }

    &:last-of-type {
        --margin: 0;
    }
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}
