.feedbackcontact {
    position: fixed;
    right: -100px;
    bottom: 45px;
    width: auto;
    z-index: 999;
    display:none;
    @media screen and (min-width: 500px) {
        display:block;
    }
    .buttons {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .feedback-icon, .contact-icon {
            cursor: pointer;
            // button höhe
            border-radius: $borderRadius;
            background-color: $primary;
            height: 48px;
            width: 48px;
            display:inline-flex;
            align-items:center;
            justify-content: center;
            transition: all 500ms ease-in-out;
            border: 2px solid #f8f8f8;
            
            img {
                width: 32px;
                height: auto;
            }

            &.hovered {
                border-radius: 5px;
            }
            @media screen and (min-width: 500px) {
                &:hover {
                    border-radius: 5px;
                }
            }
        }
        .feedback-icon {
            margin-bottom:10px;
        }
        .contact-icon {
        }
    }

    .feedbackcontact-form {
        //display: none;
        width: 326px;
        position: relative;
        .close {
            position: absolute;
            right:0px;
            top:-26px;
            background-color: $dark-50;
            padding:3px;
            width:24px;
            height:24px;
            border-radius: 6px;
            display:inline-flex;
            align-items:center;
            justify-content: center;

            &:before {
                content: '\f00d';
                font-family: 'Font Awesome 5 Free';
                font-weight: 900;
                font-size: 16px;
                color: #e8e8e8;
            }
        }
        textarea, input[type="text"], label {
            font-family: 'Helvetica-Neue', Arial, sans-serif !important;
            font-weight: 200;
            &::placeholder {
                font-family: 'Helvetica-Neue', Arial, sans-serif !important;
                font-weight: 200;
            }
        }
        .headline {
            width: 100%;
            background-color: $primary;
            color: white;
            text-align:center;
            padding: 20px;
            border-radius: $borderRadius;
            font-size: 20px;
        }
        .inner {
            width: 100%;
            background-color: $background-dark;
            border-radius: $borderRadius;
            .content {
                padding:20px;
            }
            .success {
                padding:50px 20px;
                font-size:20px;
            }
            form {
                .smileys {
                    display: flex;
                    justify-content: space-between;
                    padding: 0px 20px;

                    input[type="radio"] {
                        position:absolute;
                        visibility: hidden;
                    }
                    i {
                        border-radius: 100%;
                        cursor: pointer;
                        border: 2px solid transparent;
                    }
                    input[type="radio"][value="positive"]:checked+i, input[type="radio"][value="positive"]:hover+i {
                        color: $green;
                        background-color: white;
                        border: 2px solid white;
                    }
                    input[type="radio"][value="neutral"]:checked+i, input[type="radio"][value="neutral"]:hover+i {
                        color: $yellow;
                        background-color: black;
                        border: 2px solid black;
                    }
                    input[type="radio"][value="negative"]:checked+i, input[type="radio"][value="negative"]:hover+i {
                        color: $red;
                        background-color: white;
                        border: 2px solid white;
                    }
                }
                .submit {
                    display:flex;
                    justify-content: end;
                    margin-top:10px;
                }
            }
        }
    }
}