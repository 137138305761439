.megamenu {
    --searchbar-width: 340px;

    display: none;
    position: relative;
    background: white;

    .megamenu-wrapper {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 2%;
    }

    .megamenu-row {
        justify-content: space-between;
        padding-right: calc(var(--searchbar-width) + 15px);
    }

    .megamenu__item {
        z-index: 101;
        //width: 200px;

        .megamenu__item-container {
            position: relative;

            border: 0;
            min-height: 200px;
            max-height: 200px;

            cursor: pointer;
            //overflow: hidden;

            .megamenu__item-title {
                //position: absolute;
                display: flex;
                align-items: center;
                width: 100%;

                bottom: 0;

                margin: 0 30px 0 0;

                justify-content: center;
                height: 40px;
                transform: translate(0, 5px);
                border: 1px solid #b1b8bc !important;

                a {
                    margin: 0;
                    color: black;
                    text-decoration: none;
                    text-transform: none;
                    font-weight: 500;
                    text-align: center;

                    font-size: 14px;
                    transform: translate(0, 2px);

                    &:hover,
                    &:active,
                    &:visited,
                    &:focus {
                        text-decoration: none;
                        text-transform: none;
                        color: black;
                    }

                    @include media-breakpoint-down(md) {
                        font-size: 14px;
                    }
                }
            }

            img {
                height: 150px;
                display: block;
                object-fit: cover;
                min-height: 100px;

                border-radius: 0;

                transition: all 100ms ease-in-out;
            }
        }

        &.megamenu__item--short {
            padding-top: 5px !important;
            padding-bottom: 15px !important;
            margin-top: 0 !important;

            .megamenu__item-container {
                height: 45px;
                min-height: 45px;
                max-height: 45px;

                .megamenu__item-title {
                    border: 0;

                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        width: 100%;
                        height: 100%;
                        text-transform: uppercase;
                        transform: translate(0, 0);

                        padding-top: 5px;
                    }
                }
            }
        }

        .active-item {
            .megamenu__item-title {
                background-color: $primary;
                border-color: $primary !important;

                a {
                    color: white !important;
                }
            }
        }

        &::after {
            opacity: 0;
            display: inline-block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 20px 20px 20px;
            border-color: transparent transparent $primary transparent;

            content: '';

            position: absolute;
            bottom: 0;

            left: 50%;
            transform: translate(-50%, -190px);
            z-index: -1;

            transition: all 200ms ease-in-out;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }

        &.active {
            &::after {
                opacity: 1;
                transform: translate(-50%, 15px);
            }
        }
    }

    .megamenu__nav {
        opacity: 0;
        position: absolute;
        background-color: $primary;

        border-radius: $borderRadius-nav;

        padding: 20px;
        top: calc(100% - 0px);
        z-index: 100;
        transform: translate(0, -190px);

        width: calc(100% - 30px);
        left: 15px;

        pointer-events: none;

        transition: all 200ms ease-in-out;

        .close {
            @media (min-width: 757px) {
                display: none;
            }

            i {
                color: #fff;
            }
        }

        &.open {
            opacity: 1;
            transform: translate(0, 14px);
            pointer-events: all;
            z-index: 2;
        }

        .megamenu__nav-content {
            ul {
                list-style: none;
                margin: 0;
                padding: 0;

                li {
                    &.megamenu__nav-item {
                        margin: 5px 0;

                        a {
                            font-weight: 400;
                            font-size: 15px;

                            overflow-x: hidden;
                            word-wrap: break-word;
                            hyphens: auto;
                            transition: all 400ms ease-in-out;
                        }

                        // &:hover {
                        //     a {
                        //         text-shadow: 0 0 0.65px white, 0 0 0.65px white;
                        //         transition: all 0.5s;
                        //     }
                        // }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        display: block;

        .megamenu__item {
            &::after {
                transform: translate(-50%, -220px);
            }

            &.active {
                &::after {
                    transform: translate(-50%, 0);
                }
            }
        }

        .megamenu__nav {
            transform: translate(0, -220px);

            &.open {
                transform: translate(0, 0);
            }
        }
    }
}

.menu-searchbar {
    --spacer: 15px;

    position: relative;
    right: 0;
    top: 0;
    z-index: 1001;

    margin-top: calc(var(--spacer) * -1);
    margin-bottom: var(--spacer);

    display: flex;
    justify-content: space-between;

    width: 100%;
    height: 40px;

    transition: width 300ms ease-in-out;

    border: 1px solid $gray;
    background-color: white;

    &--inline {
        margin: 0;
    }

    .menu-searchbar__input {
        padding: 0 10px;
        width: 100%;
        border: 0;
        outline: 0;
        background-color: transparent;

        color: $font;
        font-weight: 300;

        padding-top: 5px;

        &:focus-within ~ .menu-searchbar__clear {
            opacity: 1;
            pointer-events: all;
        }

        &::placeholder {
            font-family: 'Helvetica-Neue', Arial, sans-serif !important;
            font-weight: 200;
        }
    }

    .menu-searchbar__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        height: 100%;
        width: 40px;
        border-left: 1px solid $gray;

        cursor: pointer;
    }

    .menu-searchbar__clear {
        opacity: 0;
        pointer-events: none;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 40px;
        width: 40px;
        background-color: transparent;

        position: absolute;
        right: 40px;
        top: 0px;

        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &::after {
            @extend .fapa-icon;
            @extend .fapa-icon--close;

            content: '';
            cursor: pointer;

            --height: 11px !important;
            --width: 11px !important;
            --background: #b1b8bc !important;
        }

        &.show {
            pointer-events: all;
            opacity: 1 !important;
        }
    }

    &:not(:valid) ~ .close-icon {
        display: none;
    }

    /* &:focus-within ~ .menu-searchbar__clear {
        background-color: yellow;
    } */

    @include media-breakpoint-up(lg) {
        position: absolute;
        top: 10px;
        right: var(--spacer);

        width: 340px;
        margin: 0;

        &:focus-within {
            width: calc(100% - 30px);
        }

        &:not(:focus-within) {
            width: 340px;
        }
    }
}

.typeahead {
    --spacing: 10px;

    &.dropdown-menu {
        width: calc(100% + 2px);
        transform: translate(-1px, var(--spacing));
        background-color: white;

        border: 0;
        border-radius: 0;

        border: 1px solid $gray;
    }
}
