body {
    --sidemenu-width: 100vw;
    --animation-speed: 200ms;

    .sidemenu {
        --header-size: 56px;
        transform: translate(calc(0vw - (var(--sidemenu-width) * -1)), 0);
    }

    .sidemenu__backdrop {
        position: absolute;
        top: 0;
        left: 0;

        width: 100vw;
        height: 100vh;

        background: transparent;
    }

    .sidemenu,
    .main {
        transition: transform var(--animation-speed) ease-in-out;
    }

    &.active-sidemenu {
        overflow: hidden;

        .main,
        .sidemenu {
            transition: transform var(--animation-speed) ease-in-out;
        }

        /* .main {
            transition: transform var(--animation-speed) ease-in-out;
            transform: translate(calc(var(--sidemenu-width) * -1), 0);
        } */

        .sidemenu {
            transition: transform var(--animation-speed) ease-in-out;
            transform: translate(calc(100vw - var(--sidemenu-width)), 0);
        }
    }

    @include media-breakpoint-up(lg) {
        .main {
            transform: translate(0, 0) !important;
        }
    }
}

.sidemenu {
    --spacer: 15px;
    --max-width-list: 75vw;

    position: fixed;
    width: var(--sidemenu-width);
    height: 100vh;
    background: $background-light;
    z-index: 1003;

    border-right: 1px solid $background;

    .sidemenu__header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: var(--header-size);
        background-color: transparent;
        padding: 0 var(--spacer);

        .fapa-icon {
            --height: 22px;
            --width: 22px;
        }
    }

    .sidemenu__user {
        font-size: 18px;
        color: $font-light;
        font-weight: 200;
        text-transform: uppercase;
        strong {
            color: $font;
        }
    }

    .sidemenu__content {
        padding: var(--spacer);
    }

    .sidemenu__list {
        padding: 0;
        list-style: none;
        margin: calc(var(--spacer) * 3) 0 0 0;

        li {
            a {
                display: block;
                color: #707070;
                text-transform: uppercase;
                letter-spacing: 1.5px;
                margin-bottom: 10px;
                font-size: 22px;

                font-weight: 100;
            }

            &.sidemenu__list--item {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                text-align: right;

                a {
                    font-size: 22px;
                    max-width: var(--max-width-list);
                }

                &.open {
                    .sidemenu__sublist {
                        display: flex;
                        overflow: visible;
                    }
                }
            }
        }

        .sidemenu__sublist {
            display: none;
            overflow: hidden;

            flex-direction: column;
            align-items: flex-end;

            padding: 0;
            list-style: none;
            margin: 0 0 calc(var(--spacer) * 3) 0;

            &::before {
                display: block;
                content: '';
                height: calc((var(--spacer) * 2));
                margin-bottom: calc(var(--spacer) + 5px);
                width: 1px;
                background-color: #707070;
            }

            li {
                &.sidemenu__sublist--item {
                    a {
                        font-size: 14px;
                        font-weight: 500;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        display: none;
        visibility: none;
        pointer-events: none;
    }
}
