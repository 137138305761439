.documents {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    margin-bottom: -15px;
}

.document {
    --document-size: 50px;
    --document-spacing: 15px;
    --document-radius: 5px;
    --document-image-bg: $background;
    --document-image-color: #212529;

    --document-width: calc(50% - (var(--document-spacing) / 2));

    position: relative;

    display: grid;
    grid-template-columns: var(--document-size) 1fr;
    grid-template-areas: '. .';

    width: var(--document-width);

    background-color: $background-light;
    padding: calc(var(--document-spacing) / 2);
    border-radius: var(--document-radius);

    margin-bottom: 15px;

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;

        height: var(--document-size);
        width: var(--document-size);
        border-radius: var(--document-radius);
        background-color: var(--document-image-bg);

        flex-shrink: 0;

        span {
            font-size: 14px;
            text-transform: uppercase;
            translate: 0 2px;
            color: var(--document-image-color);
        }
    }

    &__content {
        display: flex;
        align-items: center;

        padding: 0 calc(var(--document-spacing) * 1.5);
        padding-right: 10px;

        min-width: 0;
        max-width: calc((100% - var(--document-size)) - 10px);

        span {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            line-height: 1;
            padding-top: 3px;
        }
    }

    &__download {
        position: absolute;

        display: flex;
        align-items: center;
        justify-content: center;

        right: var(--document-spacing);
        top: 50%;

        transform: translate(0, -50%);

        height: 40px;
        width: 40px;
        border-radius: 40px;
        background-color: $primary;

        i {
            --height: 18px;
            --width: 18px;
            --background: #fff;
        }
    }

    i {
        display: block;
    }

    &--pdf {
        --document-image-bg: #b7565d;
        --document-image-color: #fff;
    }

    &--xlsx,
    &--xls,
    &--csv,
    &--xml {
        --document-image-bg: #64b29f;
        --document-image-color: #fff;
    }

    &--doc,
    &--docx {
        --document-image-bg: #5d7498;
        --document-image-color: #fff;
    }

    &--mp4,
    &--mpeg {
        --document-image-bg: #ba8f7c;
        --document-image-color: #fff;
    }

    &--png,
    &--jpg,
    &--jpeg,
    &--tiff,
    &--svg {
        --document-image-bg: #c6a6c9;
        --document-image-color: #fff;
    }

    /*  &:last-of-type {
        margin-bottom: 0;
    } */

    @include media-breakpoint-down(md) {
        --document-width: 100%;
    }
}
