/* roboto-condensed-regular - latin */
@font-face {
    font-family: 'Helvetica-Neue';
    font-style: normal;
    font-weight: 100;
    src: url('../../../fonts/HelveticaNeueLTStd-Th.otf') format('opentype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Helvetica-Neue';
    font-style: normal;
    font-weight: 200;
    src: url('../../../fonts/HelveticaNeueLTStd-Lt.otf') format('opentype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Helvetica-Neue';
    font-style: normal;
    font-weight: 300;
    src: url('../../../fonts/HelveticaNeueLTStd-Roman.otf') format('opentype'); /* Safari, Android, iOS */
}

@font-face {
    font-family: 'Helvetica-Neue';
    font-style: normal;
    font-weight: 500;
    src: url('../../../fonts/HelveticaNeueLTStd-Md.otf') format('opentype'); /* Safari, Android, iOS */
}
