.cms__container {
    --header-size: 136px;
    --padding: 16px;

    display: flex;
    flex-wrap: wrap;

    @extend .col-12;
    padding: 0;

    p {
        color: $font-light;
        font-size: 17px;
        font-weight: 100;
    }

    h1,
    h2 {
        margin-bottom: 20px;
        font-weight: 200;
    }

    h5 {
        color: $font-light;
    }

    .cms__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        &--inline {
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 0;
        }
    }

    .cms__row {
        display: flex;
        flex-direction: row;
    }

    .cms__header {
        margin-top: $spacer;
    }

    .cms__controls {
        background-color: #f2f2f2;
        padding: var(--padding);

        margin-bottom: -1.5rem;
        margin-top: 20px;
        border-radius: 5px;

        .btn {
            display: inline-flex;
        }
    }

    @include media-breakpoint-up(s) {
        .cms__controls {
            margin-bottom: inherit;

            width: calc(100% - 30px);
            margin-left: 15px;
        }
    }

    @include media-breakpoint-up(md) {
        .cms__controls {
            margin-bottom: inherit;

            width: calc(100% - 30px);
            margin-left: 15px;
        }
    }

    @include media-breakpoint-up(lg) {
        padding: $spacer * 2 0 0 0;

        .cms__header {
            margin-top: 0;
        }

        .cms__sticky {
            position: sticky;
            top: calc(var(--header-size) + var(--padding));
        }

        .cms__footer {
            &--inline {
                margin-top: 40px;
            }
        }

        .cms__controls {
            width: 100%;
            margin-left: 0;
        }
    }
}

// default
.swiper {
    max-width: calc(100vw - 30px);
    height: 100%;
}

.swiper-wrapper {
    max-height: 100%;
    height: 100%;
    display: flex;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: $background-light;

    width: auto;
    flex-shrink: 0;
    display: block;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    overflow: hidden;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
}

.swiper-slide {
    background-size: cover;
    background-position: center;
}

.mySwiper2 {
    height: 400px;

    @include media-breakpoint-down(md) {
        height: 290px;
    }
}

.mySwiper {
    height: 100px;
    box-sizing: border-box;
    margin: 15px 0;
}

.mySwiper .swiper-slide {
    height: 100%;
    border: 4px solid transparent;
    cursor: pointer;
}

.mySwiper .swiper-slide-thumb-active {
    opacity: 1;
    border: 3px solid $primary;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
