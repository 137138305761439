@mixin centerElement() {
    left: 50%;
    transform: translate(-50%, 0);
}

@mixin sceletonAnimation() {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

@mixin removeScrollbar() {
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
}
