.sortable {
    width: 100%;

    .sortable__item--empty {
        cursor: grab;
    }

    .sortable-chosen {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03), 0 2px 4px rgba(0, 0, 0, 0.03), 0 4px 8px rgba(0, 0, 0, 0.03), 0 8px 16px rgba(0, 0, 0, 0.03), 0 16px 32px rgba(0, 0, 0, 0.03),
            0 32px 64px rgba(0, 0, 0, 0.03);
    }

    .sortable__item--drag {
        opacity: 0.3;
        cursor: grabbing;
    }
}
