.availability-badge {
    --availability-badge-width: 25px;
    display: inline-flex;
    height: 25px !important;
    width: var(--availability-badge-width) !important;
    border-radius: 25px !important;

    background-color: gray;

    &--green {
        background-color: green;
    }

    &--orange {
        background-color: orange;
    }

    &--absolute {
        position: absolute;
        top: 0;
    }

    color: white;
}

.availability-badge-legend {
    display: block;
    padding: 8px;

    strong {
        display: block;
        margin: 10px 0 10px 0;
        width: 100%;

        font-size: 15px;
    }

    .availability-badge {
        --availability-badge-width: auto;

        padding: 3px 20px;
        font-weight: 300;

        text-transform: capitalize;
        justify-content: center;
    }

    &__row {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}
