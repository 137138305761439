.mainnavigation {
    --logo-size: 70px;
    --spacer: 15px;
    --height: 48px;

    background: white;

    .container {
        position: relative;
    }

    .navbar-brand {
        position: relative;

        img {
            height: auto;
            width: var(--logo-size);
        }

        span {
            position: absolute;
            bottom: 0;
            margin-left: 10px;
            opacity: 0.5;
            font-size: 15px;

            display: none;
        }
    }

    .navbar-collapse {
        background: $fallback;
        display: none;
        position: absolute;
        left: calc(#{var(--logo-size)} + 30px);
        top: 85px;
        border-radius: 5px;
        transition: all 300ms ease-in-out 0s;

        z-index: 1002;

        @include media-breakpoint-down(md) {
            &.collapse:not(.show) {
                display: none !important;
            }
        }
    }

    ul {
        &.navbar-nav {
            li {
                &.nav-item {
                    display: flex;
                    justify-content: flex-end;

                    padding: 5px 20px;
                    min-width: 150px;
                    margin-bottom: 0;

                    a {
                        transition: all 300ms ease-in-out;
                        font-weight: 100;
                    }

                    &:hover,
                    &:focus,
                    &:active {
                        a {
                            color: $primary;
                        }
                    }
                }
            }
        }
    }

    .navbar-toggler {
        display: none;

        border: 0;
        &:hover,
        &:focus,
        &:active {
            border: 0;
            outline: none;
            box-shadow: none;
        }
    }

    .mainnavigation-mobile {
        display: flex;
        justify-content: space-between;
        width: calc(100% - var(--logo-size) - 20px);
        padding-left: var(--spacer);

        .mainnavigation-mobile__container {
            display: flex;
            flex: 1;
            justify-content: space-between;
            align-items: center;
        }

        .mainnavigation__menu {
            display: flex;
            justify-content: space-between;
            align-items: center;

            /* width: calc(100% - 50px); */
            width: calc(100% - 100px);
            max-width: 250px;

            i,
            a {
                display: flex;
                font-size: 19px;
            }
        }

        .mainnavigation-mobile__dropdown {
            display: flex;
            align-items: center;
            flex-direction: column;

            position: absolute;
            background: white;
            width: 100vw;
            left: 50%;
            top: -100px;

            z-index: -1;
            top: var(--height);

            transform: translate(-50%, 0);

            .mainnavigation-mobile__item {
                display: none;
                width: 100%;
                background-color: white;

                padding: calc(var(--spacer) * 0.7) var(--spacer);
                border-top: 1px solid $background;
                border-bottom: 1px solid $background;

                &--active {
                    margin-bottom: -1px;
                    display: block;

                    &.mainnavigation-mobile__item--menu {
                        padding-top: 15px;
                    }
                }
            }

            .mainnavigation-mobile__link {
                display: block;
                margin-bottom: 10px;

                i {
                    margin-right: 6px;
                    opacity: 0.3;
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:hover,
                &:focus,
                &:active {
                    background: transparent;
                    color: $primary;
                }
            }
        }

        @media screen and (min-width: 425px) {
            .mainnavigation__menu {
                width: calc(100% - 100px);
            }
        }

        @include media-breakpoint-up(lg) {
            display: none;
            visibility: none;
            pointer-events: none;
        }
    }

    .dropdown {
        .dropdown-toggle {
            &::after {
                position: absolute;
                top: 50%;
                translate: 2px calc(-50% + -2px);

                height: 20px;
                width: 20px;

                background-color: $primary;

                mask: url(../../../icons/chevron.svg);
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;
            }
        }

        .dropdown-menu {
            padding: 10px;
            border-radius: 0;
            border: 0;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.02), 0 4px 8px rgba(0, 0, 0, 0.02), 0 8px 16px rgba(0, 0, 0, 0.02),
                0 16px 32px rgba(0, 0, 0, 0.02), 0 32px 64px rgba(0, 0, 0, 0.02);

            .dropdown-item,
            .dropdown-divider {
                padding: 0;
                margin: 0;
            }

            .dropdown-item {
                height: 40px;
                padding: 0 15px;
                padding-top: 10px;

                color: $gray !important;

                &:hover {
                    background: transparent;
                    color: $primary !important;
                }
            }
        }
    }

    @include media-breakpoint-up(lg) {
        --logo-size: 100px;

        display: block;
        .navbar-collapse {
            display: flex;
            background: transparent;
            top: 15px;
        }

        .navbar-toggler {
            display: none;
        }

        ul {
            &.navbar-nav {
                li {
                    &.nav-item {
                        padding: 0 5px;

                        justify-content: inherit;
                        min-width: inherit;

                        &:last-of-type {
                            padding-right: 0;
                        }

                        i {
                            margin-right: 5px;
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
    }
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0; // remove the gap so it doesn't close
}

.language-switch {
    display: flex;
    align-items: center;

    .language-switch__item {
        position: relative;

        display: flex;
        align-items: center;
        text-transform: uppercase;

        color: $gray !important;
        font-weight: 100;

        &--active,
        &:hover {
            color: $dark !important;

            &::before {
                position: absolute;

                content: '';
                height: 2px;
                width: 20px;
                bottom: -5px;
                background-color: $primary;
            }
        }

        &::after {
            content: '';

            display: block;
            width: 1px;
            height: 1em;
            background-color: $gray;

            margin: 0 8px;
        }

        &:last-of-type {
            &::after {
                content: none;
            }
        }
    }
}

.nav-item {
    position: relative;

    .bookmark-badge {
        position: relative;
        right: 0;
        bottom: 0;
        height: 2em;
        font-size: 10px;
        background-color: $primary;
        border-radius: 200px;
        line-height: 18px;
        padding: 3px 0.81em 0;
        text-align: center;
        color: white;
        align-self: center;
        &.hide {
            display: none;
        }
    }
}

.nav-item.nav-active a::after {
    background-color: $primary;
}
