.modal .fapa-modal {
    --border-radius: 1px;
    --background-header: transparent;
    --spacing: 15px;

    --input-height: 48px;
    --textarea-height: 100px;

    --width: calc(100vw - 30px);
    --height: calc(100vh - 30px);

    margin: 0;
    padding: 0;

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) !important;

    min-width: var(--width);
    max-width: var(--width);

    min-height: var(--height);
    max-height: var(--height);

    @include media-breakpoint-up(md) {
        --width: calc(100vw - 100px);
        --height: calc(100vh - 100px);
    }

    @include media-breakpoint-up(lg) {
        --width: calc(100vw - 300px);
        --height: calc(100vh - 300px);
    }

    @include media-breakpoint-up(xl) {
        --width: 1150px;
        --height: calc(100vh - 150px);
    }

    .modal-content {
        max-width: var(--width);
        min-width: var(--width);
        border-radius: var(--border-radius);
    }

    .modal-header {
        align-items: center;
        background-color: var(--background-header);

        .modal-title {
            transform: translate(0, 4px);
            text-transform: uppercase;
            color: $font;
        }

        button.close {
            span {
                display: none;
            }

            &::after {
                content: '';

                display: block;
                height: 17px;
                width: 17px;
                @extend .fapa-icon;
                @extend .fapa-icon--close;
            }
        }
    }

    .modal-body {
        .form-group {
            label {
                color: $font;
                text-transform: uppercase;
                line-height: 1.5;
                margin-bottom: calc(var(--spacing) - 5px);
                margin-top: calc(var(--spacing) - 5px);
            }

            input,
            textarea {
                border-radius: var(--border-radius);
                min-height: var(--input-height);

                font-size: 14px;
                font-weight: 100;
                color: $font-input;
                letter-spacing: 1.5px;

                padding-top: 11px;
            }

            textarea {
                min-height: var(--textarea-height);
            }
        }

        .dropzone {
            border-radius: var(--border-radius);

            button {
                color: $primary;
                text-transform: uppercase;
                transform: translate(0, 5px);
                font-weight: 100;
                letter-spacing: 1.5px;
                font-size: 14px;
            }
        }

        /*@include removeScrollbar();*/
        @extend .select2-custom;
    }

    &--article {
        .modal-body {
            padding: 15px 0 0 0;
        }
    }
}

.popup-modal,
.request-modal {
    &__table {
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        thead {
        }

        tbody {
        }

        tbody,
        thead {
            display: flex;
            flex-direction: row;
        }

        tr {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        td {
            height: 21px;
            margin-top: 8px;
            margin-bottom: 7px;
            white-space: nowrap;
        }

        th,
        td {
            width: 100% !important;
        }
    }
}

.popup-modal.hidden,
.request-modal.hidden {
    display: none !important;
}

.popup-modal .modal-hidden {
    display: none !important;
}

.modal-dialog {
    max-width: fit-content !important;
}
