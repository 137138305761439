.dashboard {
    &-info {
        margin-bottom: 15px;
        border-radius: 2px;

        border: 1px solid #e8e8e8;

        &__header {
            padding: 15px 15px 10px 15px;
            border-bottom: 1px solid #e8e8e8;

            font-size: 14px;
            font-weight: 500;
            color: $font-dark;
        }

        &__content {
            padding: 15px 15px 5px;
            font-size: 14px;
            font-weight: 200;
            color: #707070;

            i {
                --height: 16px;
                --width: 16px;
                --background: #707070;

                display: inline-flex;
                margin-right: 10px;
            }

            span {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-bottom: 5px;
            }
        }
    }

    &-subline {
        color: $font;
        font-weight: 200;
    }

    &-table {
        --spacing: 15px;

        &__header {
            height: 40px;
            background-color: $background-light;

            span {
                font-size: 14px;
                font-weight: 500;
                color: $font-dark;

                padding-top: 5px !important;
                width: 50%;
                padding: 0 var(--spacing);
                border-right: 1px solid #e8e8e8;
            }
        }

        &__row {
            margin: 0 var(--spacing);

            span {
                font-size: 14px;
                font-weight: 200;
                color: #707070;

                width: 50%;
                padding-top: 5px !important;
                padding: 0 var(--spacing);
                border-right: 1px solid #e8e8e8;
                margin: calc(var(--spacing) / 2) 0;

                &:first-of-type {
                    padding-left: 0 !important;
                }
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }

        &__header,
        &__row {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #e8e8e8;

            span {
                &:last-of-type {
                    border-right: 0;
                }
            }
        }
    }
}
