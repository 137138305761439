$primary: #e3000f;
$primary-75: #e63021;
$primary-50: #eb4d34;
$primary-25: #ed6849;

$gray: #b1b8bc;
$gray-75: #b9bfc2;
$gray-50: #c1c6c9;
$gray-25: #c8ced2;

$dark: #485155;
$dark-75: #5a6468;
$dark-50: #6d777c;
$dark-25: #7f898f;

$background: #e8e8e8;
$background-light: #f8f8f8;
$background-dark: #ececec;
$border-color: #d3d3d3;

$fallback: $background-light;

$font: #575757;
$font-light: #707070;
$font-input: #555;
$font-dark: #212529;

$font-lighter: #838383;
$font-lighter--hover: #d3d3d3;

// Colors
$blue: $primary;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
