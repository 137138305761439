.fapa-icon {
    --height: 26px;
    --width: 26px;
    --background: black;

    height: var(--height);
    width: var(--width);

    background-color: var(--background);

    &--menu {
        mask: url(../../../icons/menu.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--close {
        mask: url(../../../icons/close.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--next {
        mask: url(../../../icons/arrow.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--check {
        mask: url(../../../icons/check.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--search {
        mask: url(../../../icons/search.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--arrow {
        mask: url(../../../icons/chevron.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--trash {
        mask: url(../../../icons/rotate-cw.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--file {
        mask: url(../../../icons/file.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--phone {
        mask: url(../../../icons/phone-call.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--mail {
        mask: url(../../../icons/at-sign.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--download {
        mask: url(../../../icons/download.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--home {
        mask: url(../../../icons/home.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }

    &--color_swatches {
        mask: url(../../../icons/color-swatches.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
    }
}
