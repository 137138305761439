.result {
    position: relative;

    /* background: rgba($color: white, $alpha: 1); */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    border-radius: 1px;
    overflow: hidden;

    margin-bottom: $spacing;
    transition: all 300ms ease-in-out;

    > a {
        width: 100%;
    }

    &:hover {
        color: black;
    }

    .result__image {
        width: 100%;

        img {
            width: 50%;
            height: auto;
            object-fit: cover;
        }
    }

    .result__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $spacing 0;
    }

    .result__breadcrump {
        margin-bottom: 5px;
        opacity: 0.2;
    }

    .result__desc {
        font-size: 25px;
    }

    .result__attr {
        margin-top: $spacing;

        span {
            display: flex;
            width: 100%;

            b {
                margin-right: $spacing;
            }
        }
    }

    .result__actionbar {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        right: 0;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.04);

        padding: $spacing;

        width: 100%;

        margin-top: $spacing;
        border-radius: 5px;
    }

    .result__count {
        border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
        padding-bottom: $spacing;

        .input-label {
            position: absolute;
            display: block;
            right: 10px;
            margin-top: 7px;
            opacity: 0.5;
        }
    }

    .result__exp {
        display: none;
        opacity: 0.5;
    }

    .result__delete {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        cursor: pointer;

        transition: all 300ms ease-in-out;

        margin-top: $spacing;
        opacity: 0.3;

        label {
            margin: 0;
            cursor: pointer;
        }

        &:hover {
            opacity: 1;
            color: $red;
        }
    }

    @include media-breakpoint-up(md) {
        flex-wrap: nowrap;

        > a {
            width: auto;
        }

        &.bookmark {
            flex-wrap: wrap;

            .result__content {
                width: calc(100% - 200px);
            }
        }

        .result__container {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .result__delete {
            margin-top: 0;

            label {
                margin-right: $spacing;
            }
        }

        .result__count {
            display: flex;
            align-items: center;
            border: 0;

            padding: 0;

            label {
                margin: 0;
                margin-right: $spacing;
            }
        }

        .result__actionbar {
            margin-top: 0;
            border-radius: 0;
        }

        .result__image {
            width: 150px;
            height: 150px;

            img {
                height: 100%;
            }
        }

        .result__breadcrump {
            margin-bottom: $spacing - 10;

            a {
                font-size: 14px;
            }
        }

        .result__content {
            // padding: $spacing * 2;
            padding: $spacing;
            //padding-top: 0;
        }

        .result__desc {
            font-size: 20px;
        }
    }

    @include media-breakpoint-up(xl) {
        .result__image {
            height: 100%;
            width: 130px;
        }

        .result__actionbar {
            position: absolute;
            width: 250px;

            border-left: 5px solid white;
            border-radius: 0 5px 5px 0;
        }

        .result__count {
            width: 100%;
            flex-direction: column;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
            padding-bottom: $spacing;
            align-items: flex-start;

            label {
                display: none;
                margin-bottom: 5px;
            }
        }

        .result__container {
            width: 100%;
            flex-direction: column;
            align-items: flex-start;
        }

        .result__exp {
            display: block;
        }

        .result__delete {
            margin-top: $spacing;
            display: flex;
            justify-content: space-between;
            width: 100%;
        }
    }

    @include media-breakpoint-down(md) {
        &.bookmark {
            .result__content {
                padding-bottom: 0;
            }
        }

        .result__actionbar {
            margin-top: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .result__image {
            img {
                width: 100%;
                height: 200px;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .result__image {
            width: 150px;
            height: 150px;

            img {
                height: 100%;
            }
        }
    }
}

.result__no {
    font-size: 25px;
    font-weight: 300;
}

.inventory_state {
    border-radius: 12px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    display: inline-block;
    margin: 6px;

    &.green {
        background-color: green;
    }

    &.orange {
        background-color: orange;
    }

    &.grey {
        background-color: #ccc;
    }
}

.result-item {
    --spacing: 15px;
    --image-size: 120px;
    --image-height: 120px;

    margin-bottom: var(--spacing);
    padding: var(--spacing);

    border: 1px solid $background;
    background-color: white;

    display: flex;
    flex-direction: column;

    &__container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    &__image {
        width: var(--image-size);
        height: auto;
        background-color: $fallback;

        margin-right: var(--spacing);
        flex-shrink: 0;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: var(--spacing) 0 0;

        h4 {
            font-size: 20px;
            font-weight: 300;

            margin-bottom: var(--spacing);
        }
    }

    &__row {
        display: flex;
        flex-direction: column;

        .btn {
            justify-content: center;
        }

        .btn:first-of-type {
            margin-bottom: 10px;
        }
    }

    &__products {
        display: flex;

        --scroll-padding: 14px;

        width: calc(100% + calc(var(--scroll-padding) * 2));
        transform: translate(calc(var(--scroll-padding) * -1), 0);
        //overflow: scroll;

        .product-list {
            max-height: 0px;
            overflow: hidden;
            width: 100%;

            .dataTables_wrapper {
                > .row {
                    margin: 0;

                    .col-sm-12 {
                        padding: 0;
                    }
                }
            }
        }

        &.show {
            .product-list {
                display: block;
                margin-top: var(--spacing);

                max-height: 100%;
                padding-top: 15px;

                .row {
                    width: 100%;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            width: inherit;
            overflow: inherit;
            transform: translate(0, -21px);
        }

        /* @include media-breakpoint-down(lg) {
            .product-list {
                .dataTables_scrollHead,
                .dataTables_scrollBody {
                    overflow: visible !important;
                }
            }
        } */
    }

    td[data-link],
    td[data-article-id] {
        span {
            cursor: pointer;
            color: $primary;
            font-weight: 500;
        }
    }

    span[data-article-id] {
        color: black;
        cursor: pointer;

        &:hover {
            color: #97000a;
        }
    }

    @include media-breakpoint-up(lg) {
        --image-size: 200px;
        --image-height: 200px;

        //flex-direction: row;

        &__container,
        &__row {
            flex-direction: row;
        }

        &__row {
            .btn:first-of-type {
                margin-right: var(--spacing);
                margin-bottom: 0;
            }
        }

        &__content {
            padding: var(--spacing) 0;
        }
    }

    .js-bookmark_amount {
        width: 100%;
        max-width: 52px;
        display: inline-block;
        //margin-left: 4px;
        padding: 2px 0 0 3px;
    }
}
