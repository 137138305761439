.custom-search-bar{
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    font-weight: 400;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    outline: none;
}
tfoot{
    tr{
        td.top-linie{
            border-top: 2px solid $primary !important;
        }
    }
}

.scroll-overflow{
    overflow-x: auto
}
.chart-container{
    max-width: 800px;
    min-width: 260px;
}
.filter_revenue {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;
    flex-wrap: wrap;

    .filter__content_revenue {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        flex-wrap: wrap;
        &.full{
            width: 100% !important;
            .filter__item_revenue{
                width: 100% !important;
                .select2{
                    width: 100% !important;
                }
            }
        }

    }

    .filter__item_revenue {
        width: 100%;
        background-color: $gray-25;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 8px 10px;
        border-radius: 5px;
        margin-bottom: 10px;

        label {
            margin: 0;
            margin-right: 10px;
            opacity: 0.6;
        }

        .select2{
            max-width: 100%;
            min-width: 200px;
        }
    }

    .filter__actions_revenue {
        width: -webkit-fill-available;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;

        .filter__content_revenue {
            width: 100%;
            flex-direction: row;

            .filter__item_revenue {
                width: 100%;
                margin-right: $spacing - 5px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .filter__item_revenue {
            width: 100%;
            margin-bottom: 5px;

            flex-direction: column;
            label {
                margin-right: 0;
            }

            .select2{
                width: 100%;
                min-width: 200px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .filter__item_revenue {
            width: 100%;
            justify-content: flex-start;
            flex-direction: column;

            .select2{
                width: 100%;
                min-width: 200px;
            }
        }
    }
}
