.sidebar {
    --spacing: 15px;
    --sidebar-header-display: flex;
    margin-bottom: var(--spacing);
    background: wheat;

    &__content,
    &__header {
        position: relative;
        padding: var(--spacing);
        background-color: white;
        font-weight: 100;
    }

    &__header {
        display: var(--sidebar-header-display);
        padding: 0;

        font-size: 14px;
        font-weight: 500;
        line-height: 1;

        border-bottom: 1px solid #e8e8e8;

        h1,
        h2,
        h3,
        h4,
        h5 {
            margin: 0;
            font-size: 17px;
        }

        a,
        span {
            width: 100%;
            padding: var(--spacing);
            padding-bottom: calc(var(--spacing) * 0.6);
            font-weight: 500;
            color: #212529;
        }
    }

    &__content {
        /* border-top: 1px solid $background; */

        .nav-item {
            &__title {
                text-transform: none;

                &:hover {
                    text-transform: none;
                }
            }
        }

        > .nav-item {
            --spacer: 6px;

            display: flex;
            flex-direction: column;

            &:has(.nav-dropdown) {
                margin-bottom: calc(var(--spacer) * 1);
            }

            .nav-dropdown + .nav-subitem {
                max-height: 1000px;
                transition: max-height 1s ease-in-out;
            }
        }

        .nav-subitem {
            padding-left: 15px;

            overflow: hidden;
            max-height: 0;
            transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);

            .nav-item {
                --spacer: 5px;

                position: relative;

                a::after {
                    bottom: 2px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: -15px;
                    height: 2px;
                    width: 6px;
                    background-color: $primary;
                    transform: translate(0, 7px);
                }
            }
        }

        .nav-dropdown {
            position: relative;

            font-size: 14px;
            font-weight: 500;
            color: #575757;

            margin-bottom: calc(var(--spacer) * 0.5);
            padding-right: 20px;

            &::after {
                content: '';
                position: absolute;
                height: 16px;
                width: 16px;
                // background-color: #575757;
                background-color: transparent;

                right: 0;
                top: 0;

                mask: url(../../../icons/chevron.svg);
                mask-size: contain;
                mask-repeat: no-repeat;
                mask-position: center;

                transition: all 0.5s cubic-bezier(0, 1, 0, 1);
            }

            &.open {
                + .nav-subitem {
                    max-height: 1000px;
                    transition: max-height 1s ease-in-out;
                }
            }

            &.open,
            &:hover {
                &::after {
                    transform: rotate(180deg);
                    // background-color: $primary;
                    background-color: transparent;
                }
            }
        }
    }

    .nav-item__title::after {
        bottom: 0;
    }

    .nav-item.nav-active a::after {
        background-color: $primary;
    }

    @include media-breakpoint-down(md) {
        &__content {
            display: none;
            overflow: hidden;
        }

        &__header {
            border-bottom: 0;

            a,
            span {
                width: calc(100% - 60px);
                padding-bottom: var(--spacing);
                transform: translate(0, 2px);
            }

            &::before {
                position: absolute;

                content: '';
                height: 44px;
                width: 46px;

                right: 0;
                border-left: 2px solid #f7f7f7;
                background-color: #fff;
            }

            &::after {
                display: flex;
                align-items: center;
                justify-content: center;

                position: absolute;
                content: '\f078';

                font-family: 'Font Awesome 5 Free';
                font-weight: 900;

                width: 20px;
                height: 20px;

                top: 50%;
                right: 0;

                transform: translate(-8px, -50%);
            }
        }

        &[sidebar-collapse='true'] {
            .sidebar__header {
                &::after {
                    transform: translate(-8px, -50%) rotate(180deg);
                }
            }
            .sidebar__content {
                display: block;
                overflow: visible;
                border-top: 2px solid #f7f7f7;
            }
        }

        &--menu {
            .sidebar__header {
                &::after {
                    content: '';
                    background-color: #000;
                    mask: url(../../../icons/menu.svg);
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;

                    width: 26px;
                    height: 26px;
                }
            }
        }
    }

    &[sidebar-type='filter'] {
        .sidebar__header::after {
            right: 5px;
        }

        @include media-breakpoint-up(lg) {
            --sidebar-header-display: none;
        }
    }
}

.sidebar-accordion {
    --spacing: 15px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;

        padding: var(--spacing);
        padding-bottom: calc(var(--spacing) * 1);

        background-color: white;
        text-transform: uppercase;
        font-size: 14px;

        margin-bottom: 15px;
    }

    .sidebar {
        display: none;
        overflow: hidden;
    }

    &--active {
        .sidebar {
            display: block;
            overflow: visible;
        }
    }

    @include media-breakpoint-up(lg) {
        &__header {
            display: none;
            visibility: hidden;
        }

        .sidebar {
            display: block !important;
            overflow: visible !important;
        }
    }
}
