.lang {
    --height: 70px;
    --border-size: 3px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: $fallback;
    height: var(--height);

    border-bottom: var(--border-size) solid transparent;

    span {
        transform: translate(0, var(--border-size));
    }

    &--active {
        border-bottom: var(--border-size) solid $primary;
    }
}

.grid-container-2 {
    --spacing: 15px;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: var(--spacing);
    grid-template-areas: '. .';
}
