$slider-height: 350px !important;
$slider-padding: 30px;

.ribe-slider {
    margin-top: 15px;
    margin-bottom: 30px;

    min-height: $slider-height;
    max-height: $slider-height;

    position: relative;

    > div {
        &:first-of-type {
            border-radius: $borderRadius;
            overflow: hidden;
        }
    }

    .carousel {
        .carousel-indicators {
            display: none;
            margin: 0;
            margin-bottom: calc(#{$slider-padding} - 15px);
            justify-content: flex-end;
            margin-right: calc(#{$slider-padding} - 15px);
            li {
                background-color: $gray;
                &.active {
                    background-color: $primary;
                }
            }
        }

        .carousel-control-prev,
        .carousel-control-next {
            width: 10%;
            top: -50px;

            @media (max-width: 425px) {
                display: none;
            }
        }

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            color: $gray;
            opacity: 0.6;
            transition: 0.5s;
            background-image: none;

            &:hover {
                color: $primary;
                opacity: 1;
            }

            i {
                font-size: 40px;
            }
        }
    }

    .ribe-slider__slide {
        min-height: $slider-height;
        height: $slider-height;

        padding: $slider-padding;
        z-index: 1;

        background-color: $fallback;

        > * {
            position: relative;
            z-index: 2;
            &.ribe-slider__label {
                position: absolute;
                color: white;
                padding: 7px 20px 5px;

                text-transform: uppercase;

                font-weight: 200;
                border-radius: $borderRadius;
            }
        }

        img {
            position: absolute;
            width: 100%;
            object-fit: cover;
            left: 0;
            top: 0;
            height: $slider-height;

            z-index: 1;
        }

        .ribe-slider__subtitle {
            background-color: $gray;
            bottom: 105px;
            padding: 5px 20px;

            font-size: 14px;
        }

        .ribe-slider__title {
            background-color: $primary;
            bottom: $slider-padding;

            font-size: 25px;
        }

        .ribe-slider__type {
            top: $slider-padding;
            right: $slider-padding;

            background-color: $primary;
        }
    }

    @include media-breakpoint-up(md) {
        .carousel {
            .carousel-indicators {
                display: flex;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 60px;

        .ribe-slider__slide {
            .ribe-slider__title {
                font-size: 30px;
            }
        }
    }
}
