.print {
    background: white;

    .bookmark-content {
        margin: 0 20px;
        border: 0;
    }

    .product-list,
    .result-item__products {
        overflow: hidden;
    }

    .datatable {
        overflow: hidden;
        td,
        th {
            padding: 6px;
        }
        th {
            background: #ececec;
        }
        tr {
            &:hover {
                background: transparent !important;
                transition: 0s;
            }

            &.even {
                background: #ececec;
            }
        }
        input {
            width: 100%;
            display: block;
        }
    }
}
