.pace {
    width: 100%;
    display: block;
    border-radius: 30px;
    border: 1px solid $primary;

    background-color: white;
    color: white;
    height: 30px;
    transition: 1.5s;
    margin-bottom: 12px;

    .progress {
        height: calc(30px - 6px);
        display: inline-block;
        position: absolute;
        left: 2px;
        max-width: calc(100% - 8px);
        width: 100%;
        background-color: #e3000f;
        border-radius: 30px;
        margin: 2px;
        transition: 0.75s;
        margin-right: 4px;
    }

    .label {
        left: calc(50% - 50px);
        height: calc(30px - 6px); // Abzgl. Margin
        position: absolute;
        top: 5px;
        color: white;
        transition: 0.75s;
        font-weight: 300;
    }
}
