.filter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30px;

    flex-wrap: wrap;

    .filter__content {
        display: flex;
        flex-direction: column;

        // .filter__item {
        //     margin-right: $spacing - 5px;

        //     &:last-of-type {
        //         margin-right: 0;
        //     }
        // }
    }

    .filter__item {
        background-color: $gray-25;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 10px;
        border-radius: 5px;

        margin-bottom: 10px;

        label {
            margin: 0;
            margin-right: 10px;

            opacity: 0.6;
        }

        select {
            background: transparent;
            border: 0;
        }
    }

    .filter__actions {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        //min-width: 300px;

        a {
            margin-right: 30px;
        }
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;

        .filter__content {
            flex-direction: row;

            .filter__item {
                margin-right: $spacing - 5px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .filter__item {
            margin-bottom: 0;

            flex-direction: column;
            label {
                margin-right: 0;
            }
        }

        .filter__actions {
            min-width: 300px;
        }
    }

    @include media-breakpoint-up(xl) {
        .filter__item {
            justify-content: flex-start;

            flex-direction: row;
            label {
                margin-right: 10px;
            }
        }

        .filter__actions {
            min-width: auto;
        }
    }
}

div#DataTables_Table_0_wrapper {
    width: 100% !important;
}

.filterbar {
    --spacing: 15px;
    --spacing-negativ: calc(var(--spacing) * -1);
    &__item {
        @extend .select2-custom;

        &--header {
            /* background: rgba($color: black, $alpha: 0.05); */
            background-color: white;
            border-bottom: 1px solid $background;
            margin: var(--spacing-negativ) var(--spacing-negativ) var(--spacing) var(--spacing-negativ);
            padding: var(--spacing);
        }

        .filter-item {
            margin-bottom: var(--spacing);

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &:first-of-type {
            margin-bottom: var(--spacing);
        }
    }

    &__group {
        .filter-item {
            @extend .select2-custom;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}

.select2-custom {
    --spacing: 15px;
    --label-margin: 2px;
    --item-margin: 15px;
    --input-height: 40px;
    --border-radius: 2px;

    margin-bottom: var(--item-margin);

    label {
        width: 100%;
        font-weight: 300;
        margin-bottom: var(--label-margin);
    }

    .select2 {
        width: 100% !important;
        min-height: var(--input-height);

        .select2-selection {
            display: flex;
            align-items: center;
            min-height: var(--input-height);
            padding: 0 calc(var(--spacing) / 2);
            border-radius: var(--border-radius);
            line-height: 1;

            &.select2-selection--multiple {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;

                .select2-selection__rendered {
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 100%;
                    margin: 0;
                    padding-bottom: 7px;
                }

                .select2-selection__rendered li {
                    --height: 25px;

                    margin: 0;
                    margin: 5px 5px 0 0;

                    min-height: var(--height);
                    background-color: $primary;
                    border: none;
                    color: white;
                    font-weight: 300;

                    //padding-bottom: 5px;

                    display: flex;
                    align-items: center;

                    button,
                    .select2-selection__choice__display {
                        //min-height: var(--height);
                    }

                    button {
                        color: white;
                        border: none;
                        position: absolute;
                        top: 50%;
                        transform: translate(4px, -50%);

                        &:hover {
                            background-color: $primary;
                        }
                    }

                    .select2-selection__choice__display {
                        // position: relative;
                        // top: 8px;
                        white-space: initial;
                        margin: 5px 0 2px 0;
                    }
                }

                /* .select2-search--inline {
                    height: 30px;
                    width: 100%;

                    background: beige;
                    margin: 0 0 8px;
                    border-radius: 4px;
                } */

                .select2-search {
                    //transform: translate(0, 2px);
                }
            }
        }

        .select2-search__field {
            &::placeholder {
                color: $font;
                font-size: 13px;
                font-weight: 100;
                font-family: 'Helvetica-Neue', Arial, sans-serif !important;
                transform: translate(0, 2px);
            }
        }

        .select2-selection__rendered {
            color: $font;
            font-size: 13px;
            font-weight: 100;

            transform: translate(0, 2px);
        }

        .select2-selection__arrow {
            top: 50%;
            transform: translate(0, -50%);
            right: 10px;
        }

        &.bookmark-select {
            .select2-results__option {
                &::before {
                    content: '' !important;
                }
            }
        }
    }
}

.select2-results__option {
    display: flex;
    align-items: center;
    background-color: white;
    font-size: 13px;
    line-height: 1;
    padding: 10px 10px 8px 10px;
    font-weight: 300;

    &::before {
        content: '';
        display: flex;
        position: relative;
        height: 20px;
        width: 20px;
        border: 2px solid #e9e9e9;
        border-radius: 4px;
        background-color: #fff;
        margin-right: 10px;
        flex-shrink: 0;
    }

    &--selected {
        background-color: $background-light !important;
        color: $font !important;

        &::before {
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: '\f00c';
            color: #fff;
            background-color: $primary;
            border: 0;
            display: inline-block;
            padding-left: 4px;
            padding-top: 4px;
            font-size: 12px;
        }
    }

    &--highlighted {
        background-color: $background !important;
        color: $font !important;
    }
}

.resultlist {
    --top: 78px;
    position: relative;

    &__loading {
        position: absolute;
        top: var(--top);
        left: 0;

        height: calc(100% - var(--top));
        width: 100%;

        z-index: 1001;
        background-color: white;

        animation-name: color;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
        animation-timing-function: ease-in-out;

        opacity: 0.5;

        @keyframes color {
            to {
                background-color: $primary;
            }
        }
    }

    .heading {
        margin-bottom: 15px;
        span {
            background-color: $background-light;
            padding: 8px 15px 4px 15px;
            border-radius: 20px;

            color: $font;
            font-weight: 200;
            text-transform: uppercase;
            font-size: 14px;
        }
    }
}

/*
Styling for color inputfields
**/
.color-filter {
    --spacing: 3px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: var(--spacing);
    grid-template-areas: '. . . . .';

    &__input {
        position: relative;
        height: 50px;
        width: 50px;

        img {
            width: 100%;
            height: auto;
            border: 2px solid transparent;

            &:after {
                content: '';
                height: 100%;
                width: 100%;

                position: absolute;
                left: 0;
                top: 0;

                @include sceletonAnimation();

                border: 2px solid white;
                border-radius: 0;
            }

            &.selected {
                border: 2px solid $primary;

                &:after {
                    border: 2px solid $primary;
                }
            }

            &.selected + .color-filter__selected {
                opacity: 1;
            }
        }
    }

    &__selected {
        position: absolute;

        opacity: 0;

        height: 22px;
        width: 22px;

        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity 200ms ease-in-out;

        &::after {
            content: '';
            position: absolute;
            height: 22px;
            width: 22px;
            border-radius: 22px;
            background-color: white;
        }

        &::before {
            content: '';

            @extend .fapa-icon;
            @extend .fapa-icon--check;

            --height: 16px !important;

            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
            z-index: 1001;
        }
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas: '. . . . . . .';
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: '. . . .';
    }
}
