.sample_form_group {
    display: flex;
    gap: 10px;
    justify-content: space-evenly;
    justify-items: end;
    align-content: center;
}

.sample{
    &__modal-body{
        scrollbar-width: auto !important;
    }
}




