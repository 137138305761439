.conditions {
    &__item {
        --conditions-state: #a3cbad;
        --conditions-item-bottom: 15px;

        margin-bottom: var(--conditions-item-bottom);

        span {
            display: flex;
            /* text-transform: uppercase; */
            margin-bottom: 5px;
            color: $font-dark;
        }

        div {
            background-color: var(--conditions-state);
            padding: 15px 10px 10px;
            line-height: 1;
            border-radius: 3px;
            color: #575757;
        }

        span,
        div {
            font-size: 14px;
            font-weight: 500;
        }

        &:last-of-type {
            --conditions-item-bottom: 0;
        }
    }
}
