$modal-dialog-margin: 0.5rem !default;
$category-title-height: 90px;
$category-height: 300px;

.category {
    position: relative;

    background: $fallback;
    min-height: $category-height;
    max-height: $category-height;

    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    border-radius: $borderRadius;

    cursor: pointer;

    overflow: hidden;

    margin-bottom: $spacing * 2;

    .category__title {
        position: absolute;
        width: 100%;
        bottom: 0;
        display: flex;
        height: $category-title-height;
        background: $primary;
        align-items: center;
        justify-content: center;

        color: white;
        transition: all 300ms ease-in-out 500ms;
        padding: $spacing;
        text-align: center;
    }

    .category__overlay {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba($color: $primary, $alpha: 0.5);

        height: calc(100% - #{$category-title-height});
        width: 100%;

        padding: 30px;

        transform: translate(0, -100%);
        transition: all 300ms ease-in-out;

        transition-delay: 200ms;
        backdrop-filter: blur(3px);

        > * {
            color: white;
        }
    }

    .category__short {
        text-align: center;
    }

    .category__btn {
        position: absolute;
        bottom: 30px;
        @include centerElement();

        &::after {
            position: absolute;
            top: 0;
            display: block;
            font-family: 'Font Awesome 5 Free';
            font-weight: 900;
            content: '\f30b';
            opacity: 0;

            transform: translate(-40px, 1px);
            transition: all 400ms ease-in-out;
        }

        &:hover {
            &::after {
                opacity: 1;
                transform: translate(-30px, 1px);
            }
        }
    }

    .category__more {
        display: none;
    }

    &:hover {
        .category__title {
            transition: all 300ms ease-in-out;
            transform: translate(0, -($category-height - $category-title-height));
        }
        .category__overlay {
            transform: translate(0, $category-title-height);
        }
    }
}

.category__modal {
    .modal-content {
        border: 0;
        outline: 0;
        border-radius: $borderRadius;
    }
    .modal-header {
        background: $gray-25;
        border-radius: 0;
    }

    .modal-dialog-scrollable {
        height: subtract(100%, $modal-dialog-margin * 2);

        .modal-content {
            max-height: 100%;
            overflow: hidden;
        }

        .modal-body {
            overflow-y: auto;
        }

        .modal-footer {
            background-color: $primary-75;
            border-radius: 0;
            transition: all 400ms ease-in-out;
        }
    }

    a {
        &.category__link {
            color: white;

            .modal-footer {
                justify-content: center;

                &:hover {
                    background-color: $primary;
                }
            }
        }
    }
}

.grid-container {
    --spacing: 15px;

    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: var(--spacing);
    grid-template-areas: '.';

    padding: 0 var(--spacing);

    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas: '. .';
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas: '. . .';
    }
}

.product {
    --spacing: 15px;
    --max-image-size: 175px;

    min-height: fit-content;
    border: 3px solid transparent;

    transition: all 400ms ease-in-out;

    .product__wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        padding: 0 var(--spacing) var(--spacing) var(--spacing);
        background: $background-light;
    }

    .product__img {
        height: auto;
        width: 100%;
        max-width: var(--max-image-size);
        mix-blend-mode: multiply;
    }

    .product__title {
        width: 100%;
        text-align: center;
        padding-top: 12px;
        color: $primary;
        font-weight: 300;
        font-size: 16px;
        line-height: 1;
        letter-spacing: 1.5px;
    }

    &:hover {
        border: 3px solid $primary;
    }
}
