.article__number {
    font-weight: 200;
    margin-top: 5px;
}

.pricehistory__btn {
    width: unset !important;

    img {
        width: 30px;
        padding: 0px;
    }

    padding: 1px;

    &:hover {
        cursor: pointer;
        border: 1px solid $primary;
        padding: 0px;
    }
}
