/**
* Extra styling for print
*/

/**
* Styling for the header of
* the print class
*/
.print-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .print-logo {
        display: flex;
        justify-content: flex-end;

        height: 70px;
        width: 250px;

        img {
            height: 100%;
            width: auto;
        }
    }
}

@media print {
    .modal-header *,
    .modal-footer * {
        display: none !important;
    }

    body {
        background-color: transparent;
    }
}

.print {
    input[type='checkbox'] {
        display: none;
    }

    .fapa-print-informations {
        width: 100%;

        h3 {
            padding: 12px;
        }
    }

    .row {
        padding: 0;
        margin: 0;
    }
    button,
    .btn {
        display: none;
    }

    .modal-content {
        border-color: transparent;

        * {
            border-color: transparent;
        }

        .modal-header,
        .modal-footer {
            display: none;
        }

        .modal-body {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .product-content {
        padding: 0 !important;

        h2 {
            display: none !important;
        }
    }

    .product-informations {
        display: block !important;
    }

    .availability-badge,
    li::before,
    .tab-pane ul li::before {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
    .lightSlider {
        border: 0;

        > li {
            display: inline-block;
            padding: 6px;
            width: calc(50% - 12px);
        }
    }

    b,
    p,
    li,
    tr,
    th,
    td {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }

    ul {
        padding: 0;
    }
    li {
        list-style: none;
    }
}
