.return-page {

    .hide {
            display: none;
    }

    &__table{
        border-collapse: collapse;
        width: 100%;

        th{
            background: unset;
            border-bottom: 2px solid red;
            padding: unset;
            padding-bottom: 5px;
        }

        td{
            padding-top: .5em;
            padding-bottom: .5em;
        }

        label{
            margin: unset;
        }

        .table-quantity{
            input {
                width: 50px;
            }
        }
    }
}

.return-options{
    margin-top: 20px;
    margin-bottom: 10px;
}
