.download {
    --spacer: 15px;

    padding-bottom: var(--spacer);
    margin-bottom: var(--spacer);

    border-bottom: 1px solid $background-light;

    &__wrapper {
        position: relative;
    }

    &__image {
        flex-shrink: 0;

        width: 80px;
        height: 100px;

        background: $fallback;
        margin-right: calc(var(--spacer));
    }

    &__body {
        display: flex;
        flex-direction: row;

        margin-bottom: calc(var(--spacer) / 2);
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h5 {
            font-size: 15px;
            font-weight: 500;
            color: $primary;

            max-width: 80%;
            margin: 0;
        }

        span {
            display: flex;
            font-size: 13px;
            color: $font;

            &:first-of-type {
                margin: 2px 0 5px 0;
                opacity: 0.5;
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        align-items: center;

        height: fit-content;

        padding: 0 calc(var(--spacer) / 2);
    }

    &__info {
        position: absolute;
        top: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        height: 25px;
        width: 25px;

        background: $background;
        border-radius: 20px;
        cursor: pointer;

        i {
            font-size: 12px;
        }
    }

    &:last-of-type {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.tooltip {
    opacity: 1 !important;

    .tooltip-inner {
        border-radius: 0;
        opacity: 1 !important;

        color: $font;

        box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
    }

    .arrow,
    .tooltip-inner {
        background-color: white;
        text-align: left;
    }

    .arrow {
        opacity: 0 !important;
        color: white;
    }
}

.nav-item {
    --spacer: 15px;

    display: flex;
    flex-direction: row;
    margin-bottom: calc(var(--spacer) * 1.5);

    &__icon {
        width: 25px;
        margin-right: calc(var(--spacer) / 2);
        opacity: 0.6;
    }

    &__title,
    &__title:hover {
        font-size: 14px;
        font-weight: 500;
        color: $font;
        //text-transform: uppercase;
    }

    &__title {
        position: relative;

        &::after {
            position: absolute;
            content: '';
            width: 50px;
            height: 2px;
            bottom: -5px;
            left: 0;
            background-color: transparent;
            transition: background-color 200ms ease-in;
        }

        &:hover {
            color: black;

            &::after {
                background-color: $primary;
            }
        }
    }

    &:last-of-type {
        margin-bottom: 0;
    }
}

.nav-tabs {
    padding-bottom: 1rem;

    li.nav-item {
        margin-bottom: 0;

        a {
            position: relative;

            border-radius: 2px;
            border-color: transparent !important;

            color: rgba($color: $font, $alpha: 0.7);

            &::after {
                position: absolute;
                content: '';
                width: calc(100% - 32px);
                height: 2px;
                background-color: transparent;

                left: 50%;
                bottom: 0;
                transform: translate(-50%, 0);

                transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }

            &.active {
                color: black;
                background: transparent;

                &::after {
                    background-color: $primary;
                }
            }
        }
    }
}

.tab-pane {
    b {
        font-weight: 500;
        color: $font;
    }

    ul {
        --spacing: 10px;

        padding: 0 calc(var(--spacing) * 2);
        margin-top: var(--spacing);

        list-style: none;

        li {
            display: flex;
            align-items: center;

            margin-bottom: 8px;
            font-weight: 200;
            color: $font;

            &::before {
                content: '';
                display: block;
                height: 2px;
                width: var(--spacing);
                background-color: $primary;
                transform: translate(calc(var(--spacing) * -1), -3px);
            }
        }
    }
}
