.fapa__select {
    --spacing: 15px;

    position: relative;
    height: 45px;
    border-radius: 40px;
    padding: calc(var(--spacing) / 2) var(--spacing);
    border: none;
    min-width: 50%;

    background-color: $background-light;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
    background-repeat: no-repeat;
    background-position: right 0.7em top 50%;
    background-size: 0.65em auto;
}

.fapa__button[data-visible='false'] {
    display: none;
}

.fapa__button {
    --spacing: 20px;
    display: flex;
    align-items: center;

    border-radius: 45px;
    height: 45px;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.04rem;

    padding: calc(var(--spacing) / 2) var(--spacing);
    border: none;

    transition: all 0.2s ease-in-out;

    span {
        transform: translate(0, 2px);
    }

    &--small {
        height: 35px;

        span {
            transform: translate(0, 2px);
        }
    }

    &--primary {
        background-color: $primary;
        color: white;

        &:hover {
            color: white;
            text-transform: uppercase;
        }

        span {
            transform: translate(0, 1px);
        }
    }

    &--bordered {
        background-color: white;
        border: 2px solid $primary;

        &:hover {
            color: $white;
            background-color: $primary;

            span {
                color: white;
            }
        }

        span {
            color: $primary;
        }
    }

    &--secondary {
        color: $primary;
        background-color: transparent;

        &:hover,
        &:focus,
        &:active {
            color: $primary !important;
            text-transform: uppercase !important;
            background-color: transparent !important;
        }
    }

    &--fullwidth {
        width: 100%;
        justify-content: center;
    }

    &--icon {
        padding: 0;

        span {
            margin-right: var(--spacing);
            color: $font;
            text-transform: uppercase;
            letter-spacing: 1.5px;
            font-weight: 500;
            font-size: 17px;
        }

        i {
            width: 40px;
            background-color: $primary;
        }
    }

    &--search {
        height: 38px;
        width: 38px;

        display: flex;
        justify-content: center;
        align-items: center;

        border-radius: 0;
        border: 1px solid $gray;

        i {
            --height: 17px;
            --width: 17px;
            background-color: black;
        }
    }

    &--inline {
        display: inline-flex;
    }

    &:active,
    &:active span,
    &:focus,
    &:focus span,
    &:hover,
    &:hover span {
        text-transform: uppercase;
        outline: none;
        box-shadow: none;
    }
}

.fapa__group {
    display: flex;
    flex-direction: row;
}

.fapa__radio {
    position: relative;

    display: flex;
    align-items: center;
    flex-direction: row;

    height: fit-content;

    label {
        text-transform: uppercase;

        color: $gray;
        font-weight: 100;
        font-size: 14px;
        margin-bottom: 0;

        cursor: pointer;
    }

    input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        visibility: hidden;

        &:checked + label {
            color: #485155;

            &::before {
                position: absolute;

                content: '';
                height: 2px;
                width: 20px;
                bottom: -5px;
                background-color: $primary;
            }
        }
    }

    &::after {
        content: '';
        display: block;
        width: 1px;
        height: 1em;
        background-color: #b1b8bc;
        margin: 0 8px;
    }

    &:last-of-type {
        &::after {
            display: none;
        }
    }
}

input[type='search'] {
    &::-webkit-search-cancel-button {
        transform: translate(-2px, 0px);
        opacity: 0;
    }
}

input {
    &.table-input {
        background-color: white;
        border: 1px solid $background-light;
        padding: 3px 10px;
        &[type='number'] {
            max-width: 64px;
        }
    }
}

textarea {
    &::placeholder {
        font-family: 'Helvetica-Neue', Arial, sans-serif !important;
        font-weight: 200;
    }
}
