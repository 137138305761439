.dataTables_wrapper,
.popup-modal table,
.dataTables_wrapper table {
    color: $font !important;
    font-size: 14px;
    font-weight: 300;

    --spacing: 10px;
    --odd-color: white;
    --even-color: #f8f8f8;

    tbody {
        tr {
            &.odd {
                background-color: var(--odd-color);

                &:hover,
                &.active {
                    background-color: var(--odd-color) !important;
                }
            }

            &.even {
                background-color: var(--even-color);

                &:hover,
                &.active {
                    background-color: var(--even-color) !important;
                }
            }

            td {
                &:last-of-type {
                    padding-right: var(--spacing);
                }
            }
        }
    }

    thead {
        th {
            background-color: white;
            border-radius: 0;

            border-bottom: 2px solid $primary;
            padding-bottom: 5px;
        }
    }
}
.custom-checkout {
    display: block;
    position: relative;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    width: 20px;
    height: 20px;
    margin-bottom: 0;
    margin-right: 9px;
    top: -2px;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked {
            ~ {
                .checkmark {
                    background-color: $primary;
                    border: 2px solid $primary;

                    &:after {
                        display: block;
                    }
                }
            }
        }

        &:focus-visible {
            ~ {
                .checkmark {
                    outline: 2px solid rgb(0, 122, 255);
                }
            }
        }
    }

    .checkmark {
        &:after {
            font-size: 12px;
            color: #fff;
            left: 2px;
            top: -1px;
        }
    }
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    border: 2px solid #e9e9e9;
    border-radius: 4px;
    background-color: #fff;

    &:after {
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        content: '\f00c';
        position: absolute;
        display: none;
    }
}

.datatable {
    &__eye {
        margin: 15px 0;
        cursor: pointer;
    }

    &__display {
        display: none;
    }
}
