.dataTables_scrollHead {
    padding-bottom: $spacing;
}

table {
    tr {
        &.odd,
        &.even {
            &:hover {
                background-color: rgba($color: $primary, $alpha: 0.5);
            }
        }
    }
    .clickable {
        cursor: pointer;
    }
}

.fapa-table {
    width: 100%;

    border-bottom: 1px solid $background-dark;

    tbody th,
    tbody td {
        padding: 10px 15px 5px 15px;
        color: $font;
        font-weight: 200;
    }

    tbody tr {
        &:nth-child(odd) {
            background-color: $background-dark;
        }

        &:nth-child(even) {
            background-color: white;
        }
    }

    tbody th {
        border-radius: 0;
        background-color: transparent;
        font-weight: 500;
        color: black;
    }
}
