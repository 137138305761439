/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.chart-price {
    .tab-pane {
        ul {
            li {
                &::before {
                    display: none;
                }
            }
        }
    }
    &__loader-container {
        display: flex;
        width: 100%;
        justify-content: center;
    }
    &__loader {
        border: 16px solid #f3f3f3;
        border-radius: 50%;
        border-top: 16px solid $primary;
        width: 120px;
        height: 120px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }

    &__table {
        width: 100%;
    }

    &__container {
        background: white;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-content: center;
    }

    &__not-available {
        display: none;
        justify-content: center;
    }
}

#priceHistory.tab-pane ul li::before {
    content: none;
}

.chart-price.fullscreen {
    position: fixed;
    z-index: 1055;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0;
    background: white;

    canvas {
        width: 90% !important;
        height: auto !important;
    }
}

.chart-price-fullsize {
    margin-bottom: 20px;
}
