form {
    .form-group {
        position: relative;
    }

    label {
        &.error {
            font-size: 12px;
            color: $red !important;
        }
    }

    input,
    textarea {
        &.error {
            border-color: $red;
        }

        &.valid {
            border-color: $green;
        }
    }

    @include media-breakpoint-up(md) {
        label {
        }
    }
}

.modal {
    .modal-content {
        border-radius: $borderRadius;
        border: 0;

        .modal-header {
            background-color: $gray-25;
            border-radius: 0;
        }
    }
}
